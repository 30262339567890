import { EmailLogin } from 'components/auth/EmailLogin';
import { AuthLayout } from 'components/layout/AuthLayout';

export default function SignInPage() {
  return (
    <AuthLayout>
      <EmailLogin type="login" />
    </AuthLayout>
  );
}
